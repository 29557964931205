//@flow

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';

const LegalFR = () => (
  <>  
    <SEO title="Conditions générales d’utilisation et politique de confidentialité" />
    <p>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window && window.history.back();
        }}
      >
        &lt; Retour
      </a>
    </p>
    <h2>Conditions générales d’utilisation et politique de confidentialité</h2>
    <p>
      La société Écologie Du Corps traite les données personnelles des utilisateurs de l’application pour exécuter le contrat conclu entre elle et la personne morale, cliente d’Écologie Du Corps.
    </p>
    
    <p>
      L’objet de ce contrat est de mettre à disposition des salariés de la société cliente d’Écologie Du Corps, une application mobile depuis laquelle les salariés de la société client peuvent visionner des vidéos de posturologie, d’étirements et de correction posturale.
    </p>

    <h3>Identité et coordonnées du responsable du traitement de données :</h3>
    <p>
      Raison sociale : Écologie Du Corps<br />
      Forme juridique : SARL<br />
      Adresse de l’établissement ou du siège social : 81t boulevard d’Andilly, 95230 Soisy<br />
      Montant du capital social : 40 000 €<br />
      Numéro d’inscription au RCS : 799638390 <br />
      Numéro de TVA intracommunautaire : 34799638390 <br />
      Représentant légal : Julien Paccaud <br />
    </p>

    <h3>Données recueillies : </h3>
    <p>
      Écologie Du Corps traite les données à caractère personnel suivantes :
    </p>
    <ul>
      <li>Données d’identification : adresse mail</li>
      <li>Données liées à la vie professionnelle : situation professionnelle</li>
      <li>Données de connexion : identifiants de connexion, informations d'horodatage</li>
      <li>Données Internet : mesures d’audience</li>
    </ul>

    <h3>Finalités de la collecte des données : </h3>
    <p>
      Les données personnelles sont collectées pour permettre un accès sécurisé et individuel à l’application mobile. La finalité de l’utilisation de l’application mobile est de diminuer les effets néfastes des différentes postures de travail.
    </p>
    <p>
      Écologie Du Corps, via l’application mobile, veut mettre à disposition des personnes concernées des vidéos de posturologie, d’étirements et de correction posturale pour améliorer leur bien-être au travail, à la demande de leur employeur. 
    </p>

    <p>
      Les personnes concernées répondent également à des quizz et participent à une enquête de satisfaction sur le parcours numérique effectué. 
    </p>

    <p>
      L’application mobile permet de quantifier la participation des équipes (classées par Business Unit) aux lectures des vidéos et de les inviter, par email, à reprendre le parcours s’ils l’ont arrêté ou mis en pause.
    </p>

    <h3>Source des données :</h3>
    <p>
      Les données personnelles nécessaires à l’inscription au service proposé par l’application sont recueillies directement auprès des personnes concernées.
    </p>
    <p>
      Les données liées à la vie professionnelle,
      les données de connexion et les données Internet
      sont également recueillies directement auprès de la personne concernée,
      via l’acceptation du dépôt de cookies,
      détenus par des tiers en leur qualité de sous traitant.
    </p>

    <h3>Base juridique du traitement de données : </h3>
    <p>
      La base juridique du traitement est l’exécution du contrat passé entre Écologie Du Corps et la société cliente, employant les personnes concernées.
    </p>
    
    <h3>Consultation de l’application mobile :</h3>
    <p>
      La consultation de l’application mobile nécessite une inscription et une identification préalables de l'utilisateur.
    </p>

    <h3>Caractère obligatoire ou facultatif du recueil des données et conséquences pour la personne en cas de non-fourniture des données : </h3>
    <p>
      Le recueil des données demandées est obligatoire pour que l’utilisateur accède au service proposé par Écologie Du Corps. 
    </p>
    <p>
      En cas de non-fourniture des données, la personne concernée par le traitement ne peut pas avoir accès aux services proposés par Écologie Du Corps.
    </p>

    <h3>Communication des données à des tiers et modalités :</h3>
    <p>
    Les données personnelles des utilisateurs de l’application mobile ne sont pas communiquées à des tiers.
    </p>
    <p>
    Les données personnelles sont anonymisées, agrégées et transformées en statistiques à destination de la société cliente d’Écologie Du Corps.
    </p>
    <p>
      Les données personnelles relatives à la connexion et aux cookies sont détenues par les sociétés titulaires des solutions utilisées. Ces sociétés agissent en qualité de sous-traitant d’Écologie Du Corps et traitent les données selon les conditions d’Écologie Du Corps.
    </p>

    <h3>Destinataires des données :</h3>
    <p>
      Les destinataires des données personnelles recueillies par l’application mobile sont les personnes susceptibles d’y avoir accès. Les destinataires sont :
    </p>
    <ul>
      <li>la société éditrice de l’application mobile</li>
      <li>Écologie du Corps</li>
    </ul>

    <p>
      La société cliente d’Écologie Du Corps n’a accès qu’aux données statistiques élaborées à partir de données anonymisée. Elle n’a pas accès aux données personnelles recueillies par l’application mobile.
    </p>

    <h3>Durée de conservation des données :</h3>
    <p>
      Les données personnelles des utilisateurs de l’application mobile sont conservées 12 mois, soit 6 mois après la fin du programme, pour que les utilisateurs puissent exercer leurs droits sur leurs données.
    </p>

    <p>
      À l’issue des 12 mois, Écologie Du Corps supprime les données des personnes concernées et ne conserve que les statistiques (sous forme de données anonymisées) aux fins d’améliorations de son produit.
    </p>

    <p>
      Au moment de la suppression de leurs données personnelles, les utilisateurs de l’application mobile reçoivent une copie des statistiques réalisées à partir des données recueillies.
    </p>

    <h3>Présence de cookies et impact sur le fonctionnement du service</h3>

    <p>Le dépôt de “cookies” ou “traceurs” est strictement nécessaire pour la délivrance du service proposé par Écologie Du Corps.</p>
    <p>Les “cookies” ou “traceurs” sont déposés uniquement après que l’utilisateur du service ait donné son consentement. </p>
    <p>Sans le consentement de l’utilisateur au dépôt de “cookies” ou “traceurs”, Écologie Du Corps ne stocke pas d’informations sur l’équipement de la personne concernée et ne peut pas avoir accès aux informations déjà stockées.</p>

    <h3>Transfert des données vers un pays hors Union européenne :</h3>

    <p>
      Écologie Du Corps tient à ce que les données personnelles des utilisateurs de son application mobile restent proches d’eux et bénéficient des garanties de sécurité propres à l’Union européenne.
    </p>
    <p>
      Les données personnelles des utilisateurs de l’application mobile ne sont pas transférées en dehors de l’Union européenne.
    </p>

    <h3>Prise de décision automatisée ou profilage :</h3>

    <p>
      Les données personnelles des utilisateurs de l’application mobile ne sont pas utilisées à des fins de prise de décision individuelle automatisée, ni de profilage.
    </p>

    <h3>Avenir des données en cas de fusion/acquisition ou de fusion/absorption de l’entreprise responsable de traitement :</h3>

    <p>
      En cas de fusion/acquisition ou de fusion/absorption, Écologie Du Corps informera les utilisateurs préalablement à l’opération et à la transmission de leurs données personnelles à la nouvelle entité.
    </p>

    <h3>Les droits des personnes concernées :</h3>

    <p>
      Les utilisateurs de l’application mobile peuvent demander à exercer les droits dont ils disposent concernant leurs données personnelles.
    </p>

    <p>
      Pour exercer ces droits, ils envoient un mail à l’adresse dpo@ecologieducorps.com, en joignant au mail une copie du recto de leur carte nationale d’identité ou de leur passeport.
    </p>

    <p>
      En cas de doute raisonnable sur l’identité, Écologie Du Corps pourra demander des informations complémentaires.
    </p>

    <p>
      Les utilisateurs disposent des droits suivants :
    </p>
    <ul>
      <li>accès</li>
      <li>rectification</li>
      <li>effacement</li>
      <li>limitation</li>
    </ul>

    <p>
    Pour tout savoir sur ces droits, RDV sur le site de la CNIL : https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles
    </p>

    <h3>Coordonnées du délégué à la protection des données de l’organisme :</h3>
    <ul>
      <li>Nom, prénom : Anaïs Person</li>
      <li>Adresse mail : dpo@ecologieducorps.com</li>
    </ul>

    <h3>Mention concernant le droit des personnes d’introduire une réclamation auprès de la CNIL :</h3>
    <p>
      L’utilisateur du service a le droit d'introduire une réclamation auprès d'une autorité de contrôle, en particulier dans l'État membre dans lequel se trouve sa résidence habituelle, son lieu de travail ou le lieu où la violation aurait été commise, si elle considère que le traitement de données à caractère personnel la concernant constitue une violation du présent règlement.
    </p>
    <p>
      En France, l’autorité de contrôle est la CNIL.
    </p>
    <p>
      Les conditions pour adresser une réclamation à la CNIL sont disponibles ci-après :
      https://www.cnil.fr/fr/cnil-direct/question/844
    </p>
    <h3>En cas de litige :</h3>
    <p>
      En cas de litige, la législation française sera applicable.
    </p>
  </>
);

const LegalEN = () => (
  <>  
    <SEO title="General terms and conditions of use and privacy policy" />
    <p>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window && window.history.back();
        }}
      >
        &lt; Back
      </a>
    </p>
    <h2>General terms and conditions of use and privacy policy</h2>
    <p>
      The company My Body Ecology processes the personal data of the users of the application in order to execute the contract concluded between it and the legal entity, client of My Body Ecology.
    </p>
    <p>
      The purpose of this contract is to make available to the employees of the client company of My Body Ecology, a mobile application from which the employees of the client company can view videos of posturology, stretching and postural correction.
    </p>
    <h3>Identity and contact details of the data controller:</h3>
    <p>
      Company name: Ecologie Du Corps (My Body Ecology)<br />
      Legal form: SARL<br />
      Address of the establishment or registered office: 81t boulevard d'Andilly, 95230 Soisy, France.<br />
      Amount of share capital: €40,000<br />
      SCR Registration Number: 799638390 <br />
      Intracommunity VAT number: 34799638390 <br />
      Legal representative: Julien Paccaud <br />
    </p>
    <h3>Data Collected: </h3>
    <p>
      My Body Ecology processes the following personal data :
    </p>
    <p>
      Identification data: surname, first name, email address<br />
      Data related to working life: professional situation<br />
      Connection data: (e.g. IP addresses, logs, terminal identifiers, connection identifiers, timestamp information, etc.).<br />
      Internet data : (e.g. cookies, trackers, browsing data, audience measurements, etc.)<br />
    </p>
    <h3>Purposes of data collection: </h3>
    <p>
    Personal data is collected to allow secure and individual access to the mobile application. The purpose of using the mobile application is to reduce the harmful effects of different work postures.
    </p>
    <p>
    My Body Ecology, via the mobile application, wants to make videos of posturology, stretching and postural correction available to the persons concerned to improve their well-being at work, as requested by their employer. 
    </p>
    <p>
    The people concerned also answer quizzes and take part in a satisfaction survey on the digital path they have taken. 
    </p>
    <p>
    The mobile application makes it possible to quantify the participation of the teams (classified by Business Units) in the videos and to invite them, by email, to resume the journey if they have stopped or paused it.
    </p>
    <h3>Data source: </h3>
    <p>
    The personal data required to register for the service offered by the application is collected directly from the people concerned.
    </p>
    <p>
    Data related to professional life, connection data and Internet data are also collected directly from the data subject, via acceptance of the deposit of cookies, held by third parties in their capacity as sub-contractors.
    </p>
    <h3>Legal basis of the data processing: </h3>
    <p>
    The legal basis of the processing is the execution of the contract between My Body Ecology and the client company employing the data subjects.
    </p>
    <h3>
    Consultation of the mobile application:
    </h3>
    <p>
    Consultation of the mobile application requires prior registration and identification of the user.
    </p>
    <h3>Mandatory or optional nature of the data collection and consequences for the person in case of failure to provide the data: </h3>
    <p>
    The collection of the requested data is mandatory for the user to access the service offered by My Body Ecology. 
    </p>
    <p>
    If the data is not provided, the person concerned by the processing cannot access the services offered by My Body Ecology.
    </p>
    <h3>Communication of data to third parties and modalities:</h3>
    <p>
    The personal data of the users of the mobile application are not communicated to third parties.
    </p>
    <p>
    Personal data are anonymized, aggregated and transformed into statistics for the My Body Ecology client company.
    </p>
    <p>
    Personal data relating to the connection and cookies are held by the companies that own the solutions used. These companies act as subcontractors of My Body Ecology and process the data according to My Body Ecology's conditions.
    </p>
    <h3>Recipients of the data:</h3>
    <p>
    The recipients of the personal data collected by the mobile application are the people who may have access to it. 
    </p>
    <p>
    The recipients are :
    </p>
    <ul>
      <li>
      the publishing company of the mobile application 
      </li>
      <li>
      My Body Ecology
      </li>
    </ul>
    <p>
    The client company of My Body Ecology has access only to statistical data elaborated from anonymized data. It does not have access to personal data collected by the mobile application.
    </p>
    <h3>Data retention period: </h3>
    <p>
    The personal data of mobile application users are kept for 12 months, i.e. 6 months after the end of the program, so that users can exercise their rights to their data.
    </p>
    <p>
    At the end of the 12 months, My Body Ecology deletes the data of the persons concerned and keeps only the statistics (in the form of anonymized data) for the purpose of improving its product.
    </p>
    <p>
    At the time of deletion of their personal data, users of the mobile application receive a copy of the statistics made from the data collected.
    </p>
    <h3>Presence of cookies and impact on the operation of the service:</h3>
    <p>
    The deposit of "cookies" or "tracers" is strictly necessary for the delivery of the service offered by My Body Ecology.
    </p>
    <p>
    Cookies are deposited only after the user of the service has given his consent. 
    </p>
    <p>
    Without the user's consent to the deposit of "cookies", My Body Ecology does not store information on the equipment of the person concerned and cannot have access to information already stored.
    </p>
    <h3>Transfer of data to a country outside the European Union:</h3>
    <p>
    My Body Ecology wants the personal data of the users of its mobile application to remain close to them and benefit from the security guarantees specific to the European Union.
    </p>
    <p>
    The personal data of the users of the mobile application are not transferred outside the European Union.
    </p>
    <h3>Automated decision making or profiling:</h3>
    <p>
    The personal data of the users of the mobile application is not used for automated individual decision-making or profiling.
    </p>
    <h3>
    Future of the data in case of merger/acquisition or merger/absorption of the company responsible for processing:
    </h3>
    <p>
    In case of merger/acquisition or merger/absorption, My Body Ecology will inform users prior to the operation and the transmission of their personal data to the new entity.
    </p>
    <h3>The rights of the data subjects:</h3>
    <p>
    Users of the mobile application may request to exercise the rights they have regarding their personal data. 
    </p>
    <p>
    To exercise these rights, they send an e-mail to dpo@ecologieducorps.com, attaching a copy of the front of their national identity card or passport.
    </p>
    <p>
    In case of reasonable doubt about identity, My Body Ecology may request additional information.
    </p>
    <p>
    Users have the following rights:
    </p>
    <ul>
      <li>access;</li>
      <li>rectification;</li>
      <li>deletion;</li>
      <li>limitation.</li>
    </ul>
    <p>
    For more information on these rights, please visit the CNIL website:
    <a href="https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles">https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles</a>
    </p>
    <p>
    Contact details of MyBodyEcology Data Protection Officer :
    </p>
    <p>
    E-mail address: <a href="mailto:dpo@ecologieducorps.com">dpo@ecologieducorps.com</a>
    </p>
    <p>
    Mention concerning the right of individuals to lodge a complaint with the CNIL :
    </p>
    <p>
    The user of the service has the right to lodge a complaint with a supervisory authority, in particular in the Member State in which his habitual residence, place of work or the place where the breach is alleged to have been committed, if it considers that the processing of personal data concerning him constitutes a breach of this Regulation.
In France, the supervisory authority is the CNIL.
    </p>
    <p>
    The conditions for making a complaint to the CNIL are available below: <a href="https://www.cnil.fr/fr/cnil-direct/question/844">https://www.cnil.fr/fr/cnil-direct/question/844</a>
    </p>
    <h3>In case of dispute:</h3>
    <p>
    In the event of a dispute, French law will be applicable.
    </p>

  </>
);

const LegalPage = () => {
  const intl = useIntl();
  console.log('coucou', intl);
  
  if (intl.locale === 'fr') {
    return <LegalFR />;
  }

  return <LegalEN />;
}

export default LegalPage;
